import { render, staticRenderFns } from "./ShoppingDetail.vue?vue&type=template&id=7663b8a8&scoped=true&"
import script from "./ShoppingDetail.vue?vue&type=script&lang=js&"
export * from "./ShoppingDetail.vue?vue&type=script&lang=js&"
import style0 from "./ShoppingDetail.vue?vue&type=style&index=0&id=7663b8a8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7663b8a8",
  null
  
)

export default component.exports